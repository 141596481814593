<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>库存变动单据</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="3">
              <!-- <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              > -->
            </el-col>
          </el-row>
          <el-table
            v-loading="loading"
            class="storage-table"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column
              prop="check_no"
              label="盘点单编号"
            ></el-table-column>
            <el-table-column
              prop="document_no"
              label="变动单据编号"
            ></el-table-column>
            <el-table-column label="类型">
              <template v-slot="scope">
                <el-tag type="warning" v-if="scope.row.business_type === 1"
                  >报损单</el-tag
                >
                <el-tag v-else>报溢单</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="add_time" label="生成时间"></el-table-column>
            <el-table-column
              prop="warehouse_name"
              label="仓库"
            ></el-table-column>
            <el-table-column prop="add_uname" label="盘点员"></el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-search"
                  @click="openInfo(scope.row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <!-- <el-pagination
            @current-change="pageChange"
            @size-change="sizeChange"
            :pager-count="15"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.getInfo.page_num"
            background
            :total="exp.num"
          >
          </el-pagination> -->
        </div>
      </div>
    </el-card>

    <!-- 详情弹窗 -->

    <el-dialog width="70%" title="详情" :visible.sync="detailDiaVisible">
      <div class="info">
        <el-descriptions title="产品信息" border :column="3">
          <el-descriptions-item label="变动单据id">{{
            checkInfo.id
          }}</el-descriptions-item>
          <el-descriptions-item label="单据编号">{{
            checkInfo.document_no
          }}</el-descriptions-item>
          <el-descriptions-item label="盘点员id">{{
            checkInfo.add_userid
          }}</el-descriptions-item>
          <el-descriptions-item label="盘点仓库id"
            >{{ checkInfo.warehouse }}
          </el-descriptions-item>
          <el-descriptions-item label="盘点时间"
            >{{ checkInfo.edit_time }}
          </el-descriptions-item>
          <el-descriptions-item label="备注">{{
            checkInfo.remark
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="table" style="margin-top:5px">
        <el-table
          height="400"
          class="check-table"
          :data="checkInfo.list"
          border
        >
          <el-table-column
            prop="id"
            width="120"
            label="商品id"
          ></el-table-column>
          <el-table-column prop="mp_name" label="商品名称"></el-table-column>

          <el-table-column label="规格" prop="spec"></el-table-column>

          <!-- <el-table-column label="SKU条码"></el-table-column> -->
          <el-table-column prop="store_num" label="库存"></el-table-column>
          <el-table-column prop="check_num" label="盘点数量"> </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
import { getStoreDocList, getDocumentInfo } from '@/api/psi/check.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      tableData: [],
      loading: false,
      query: {
        page: 1,
        limit: 7,
        page_code: 1,
      },
      checkInfo: {},
      detailDiaVisible: false,
      dialogLog: {
        state: false,
      },
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 获取库存变动单据列表
    async getStoreDocList() {
      const { data: res } = await getStoreDocList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data
      } else {
        this.$message.error(res.message)
      }
    },
    async openInfo(row) {
      this.detailDiaVisible = true
      const { data: res } = await getDocumentInfo({ document_id: row.id })
      console.log(res)
      if (res.code === 1) {
        this.checkInfo = res.data
      } else {
        this.$message.error(res.$message)
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getStoreDocList()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}

.add-table >>> .el-form-item {
  margin-bottom: 0 !important;
}

.add-table >>> .el-form-item .el-input {
  width: 70px !important;
}
.storage-table >>> .el-checkbox {
  zoom: 200% !important;
}
.storage-table >>> .row-red {
  background-color: orange !important;
  font-weight: 700;
}
.storage-table >>> .row-red:hover {
  background-color: orange !important;
  font-weight: 700;
}
.storage-table .warning-yellow {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #f2f200;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
}
.storage-table .warning-red {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background-color: #870719;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
}
.storage-table .warning-orange {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: orange;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
}
.storage-table .warning-green {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #67c23a;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
}
.add-table .select .el-cascader >>> .el-input {
  width: 180px !important;
}
</style>
